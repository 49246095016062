/* eslint-env browser */
/* global PARTYKIT_HOST */

const image = document.getElementById('image');

const urlParams = new URLSearchParams(window.location.search);
const room = urlParams.get('room');

import PartySocket from 'partysocket';

const ws = new PartySocket({
  host: PARTYKIT_HOST,
  room
});

ws.onerror = error => {
  console.error('error', error);
};

ws.onopen = () => {
  console.log('connected');
};

ws.onclose = () => {
  console.log('disconnected');
};

ws.onmessage = async event => {
  console.log('message', event.data);
  const data = JSON.parse(event.data);
  if (data.type === 'image') {
    image.src = data.image;
    message.style.display = 'none';
  } else if (data.type == 'speak') {
    const audioData = await hostSay(data.script);
    const audio = new Audio(URL.createObjectURL(audioData));
    audio.play();
  } else if (data.type == 'message') {
    const message = document.getElementById('message');
    message.textContent = data.text;
    message.style.display = 'flex';
  }
};

const hostSay = async script => {
  return await fetch(
    `https://api.elevenlabs.io/v1/text-to-speech/fLx7O1c8BPErkhtgNPbX/stream`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': '5f07b067fb89a2f28e8ab3abc3d96ecf'
      },
      body: JSON.stringify({
        model_id: 'eleven_multilingual_v2',
        text: script
      })
    }
  ).then(response => response.blob());
};
